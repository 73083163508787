.gameContainer {
    display: flex;
    flex-direction: column;
    width: 600px;
    height: 800px;
    align-items: center;
    background-color: azure;
    border-radius: 10px;
  }

  .img {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .guess {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
  }

  button {
    background-color: rgb(155, 155, 155);
    width: 350px;
    height: 50px;
    font-size: 20px;
    border-radius: 5px;
    margin-bottom: 10px;
    border: none;
  }

  button:active {
    background-color: rgb(112, 112, 112);
  }

  .count { 
    text-align: center;
  }

  .wrong {
    background-color: rgb(160, 22, 22);
  }